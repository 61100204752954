<template>
  <div
    class="@lg:grid-cols-3 @xl:grid-cols-4 @2xl:grid-cols-5 grid grid-cols-2 gap-4"
  >
    <AccountWatchListMovieCard
      v-for="movie in watchList"
      :key="movie.id"
      :movie="movie"
      :hide-link="false"
      :render-above-the-fold="true"
      :link-params="[]"
      @remove="remove({ movieId: movie.id, timeout: 2000 })"
      @re-add="add({ movieId: movie.id })"
    />
  </div>
</template>

<script setup lang="ts">
import type { Movie } from '#gql/default'

interface Props {
  watchList: Movie[]
}

defineProps<Props>()
const COMPONENT_NAME = 'AccountWatchListMovieList'

const { add, remove } = useWatchList()

defineOptions({
  name: COMPONENT_NAME,
})
</script>
