import type { Movie, MoviePaginator } from '#gql/default'
export type WatchListMovie = Movie & { hasShows: boolean }

export default function useWatchList() {
  const { cookies } = useAccountCookies()
  const movies = useState<Movie[]>('watchlist-movies', () => [])
  const paginator = useState<MoviePaginator>('watchlist-paginator')
  let timeoutId: NodeJS.Timeout | null = null

  const accessToken = cookies.get(ACCOUNT_ACCESS_TOKEN_COOKIE_NAME)

  cookies.addChangeListener(() => {
    if (accessToken) {
      useGqlToken(accessToken)
    }
  })

  if (accessToken) {
    useGqlToken(accessToken)
  }

  async function fetch({
    first = 10,
    page = 1,
  }: {
    page?: number
    first?: number
  }) {
    const { data, status } = await useAsyncGql('FetchWatchList', {
      first,
      page,
    })

    watch(status, () => {
      if (status.value === 'success' && data.value) {
        setData({
          data: data.value?.me?.watchList.data,
          paginatorData: data.value?.me?.watchList.paginatorInfo,
        })
      }
    })
  }

  async function add({
    movieId,
    page = 1,
  }: {
    movieId: string
    page?: number
  }) {
    if (timeoutId) {
      clearTimeout(timeoutId!)
    }

    const { data } = await useAsyncGql('AddMovieToWatchList', {
      movieId,
      first: 10,
      page,
    })

    setData({
      data: data.value?.watchListAdd?.watchList.data,
      paginatorData: data.value?.watchListAdd?.watchList.paginatorInfo,
    })
  }

  async function remove({
    movieId,
    page = 1,
    timeout = 0,
  }: {
    movieId: string
    page?: number
    timeout?: number
  }) {
    if (timeoutId) {
      clearTimeout(timeoutId!)
    }
    const { data } = await useAsyncGql('RemoveMovieFromWatchList', {
      movieId,
      first: 10,
      page,
    })

    timeoutId = setTimeout(() => {
      setData({
        data: data.value?.watchListRemove?.watchList.data as WatchListMovie[],
        paginatorData: data.value?.watchListRemove?.watchList.paginatorInfo,
      })
    }, timeout)
  }

  function setData({
    data,
    paginatorData,
  }: {
    data: WatchListMovie[]
    paginatorData: MoviePaginator
  }) {
    movies.value = (data as unknown as WatchListMovie[]) ?? []
    paginator.value = (paginatorData as unknown as MoviePaginator) ?? {}
  }

  return {
    movies,
    paginator,
    fetch,
    add,
    remove,
  }
}
