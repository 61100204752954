import { useCookies } from '@vueuse/integrations/useCookies'
import type { AuthTokenPayload } from '#gql/default'

export default function useAccountCookies() {
  const cookies = useCookies([
    ACCOUNT_ACCESS_TOKEN_COOKIE_NAME,
    ACCOUNT_REFRESH_TOKEN_COOKIE_NAME,
  ])

  async function refreshAuthToken() {
    const payload = {
      refreshToken: cookies.get(ACCOUNT_REFRESH_TOKEN_COOKIE_NAME),
    }

    const { data, error } = await useAsyncGql(
      'AccountRefreshAccessToken',
      payload
    )

    if (error.value) {
      // TODO: fetchError.value?.cause?.gqlErrors?.[0]?.message
      throw error.value
    }

    if (data.value?.data?.refreshLogin) {
      setAuthTokenCookies(data.value.data.refreshLogin)
    }

    return data.value
  }

  function setAuthTokenCookies(payload: AuthTokenPayload) {
    const today = new Date()
    const accessTokenDate = new Date()
    const refreshTokenDate = new Date()

    accessTokenDate.setDate(today.getDate() + 15)
    refreshTokenDate.setDate(today.getDate() + 30)

    cookies.set(ACCOUNT_ACCESS_TOKEN_COOKIE_NAME, payload.accessToken, {
      expires: accessTokenDate,
      path: '/',
    })

    cookies.set(ACCOUNT_REFRESH_TOKEN_COOKIE_NAME, payload.refreshToken, {
      expires: refreshTokenDate,
      path: '/',
    })
  }

  return {
    cookies,
    refreshAuthToken,
    setAuthTokenCookies,
  }
}
